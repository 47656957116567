import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/peterkrajcovic/Documents/Web/GatsbyProject/globaltel_V5_actual/src/components/newsLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>Reakcia firmy Globaltel na medializované informácie o projekte MÚK-P</h1>
    <p>
    Spoločnosti Globaltel a SWAN vítajú začatie vyšetrovania, ktoré inicioval Najvyšší kontrolný úrad (NKÚ) v súvislosti
    s kontrolou v NASES, pretože všetky doposiaľ zverejnené informácie nám pripadajú účelové alebo prinajmenšom značne
    zjednodušené.
    </p>
    <p>
    Nie je šťastné, že doteraz nie je zverejnený protokol z kontroly v plnom rozsahu, čo neprispieva k transparentnosti
    vykonanej kontroly. Spoločnosť GlobalTel, a.s. požiadala o sprístupnenie protokolu z kontroly v zmysle Zákona
    211/2000 Z.z. o slobodnom prístupe k informáciám, no s poukázaním na trestné oznámenie zo strany NKÚ nám
    sprístupnenie bolo odmietnuté.
    </p>
    <p>
    Vieme sa preto vyjadriť len k informáciám, ktoré na verejnosť prenikajú postupne, napríklad aj vo vyhláseniach
    podpredsedu NKÚ Ľubomíra Andrassyho pre médiá. Mnohé jeho tvrdenia sú však v prudkom rozpore s našim zmluvným
    vzťahom s NASES-om. Nakoľko zmluvy sú verejné, vie si to verejnosť overiť, na rozdiel od informácií z protokolu o
    kontrole, ktorý NKÚ zverejniť odmieta.
    </p>
    <p>
    Aj preto by sme privítali viesť túto debatu na odbornej úrovni a odmietame sa zapájať do neférového politického
    boja. Sme dlhodobým partnerom vlády a už viac ako 18 rokov spolupracujeme a dodávame štátu riešenia v oblasti
    eGovermentu, vďaka ktorým dnes funguje komunikácia štátnej správy s občanmi i jej orgánmi navzájom omnoho
    efektívnejšie.
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      